<div class="container">
  <br>
  <div class="row">
    <div class="card col-12">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h2>Staff</h2>
          <button type="button" class="btn btn-yellow justify-content-end" (click)="openAddStaffModal(addStaffModal)">Nuevo Staff</button>
        </div>
        <br>
        <div class="table-wrapper">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Correo</th>
                <th scope="col">Tipo</th>
                <th scope="col">Creado el</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let staff of staffs">
                <th scope="row">{{staff.name }}</th>
                <td>{{staff.email}}</td>
                <td>
                  <span *ngIf="staff.idUserType == 1">Administrador</span>
                  <span *ngIf="staff.idUserType == 2">Staff</span>
                </td>
                <td>{{staff.createdAt | date }}</td>
                <td>
                  <span *ngIf="staff.status == 1" class="badge badge-success">Activado</span>
                  <span *ngIf="staff.status == 0" class="badge badge-danger">Desactivado</span>
                </td>
                <td>
                  <span *ngIf="staff.status == 1" (click)="openModalWithInfo(editStaffModal, 'editStaffModal', staff)" class="pointer">
                    <svg class="bi bi-pencil" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M13.293 3.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM14 4l2 2-9 9-3 1 1-3 9-9z"
                        clip-rule="evenodd"></path>
                      <path fill-rule="evenodd"
                        d="M14.146 8.354l-2.5-2.5.708-.708 2.5 2.5-.708.708zM5 12v.5a.5.5 0 00.5.5H6v.5a.5.5 0 00.5.5H7v.5a.5.5 0 00.5.5H8v-1.5a.5.5 0 00-.5-.5H7v-.5a.5.5 0 00-.5-.5H5z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </span>
                  <span *ngIf="staff.status == 0" (click)="clickDisableStaff(staff)" class="pointer">
                    <svg class="bi bi-toggle-off" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M13 6a4 4 0 010 8h-3a4.992 4.992 0 002-4 4.992 4.992 0 00-2-4h3zm-6 8a4 4 0 110-8 4 4 0 010 8zm-5-4a5 5 0 005 5h6a5 5 0 000-10H7a5 5 0 00-5 5z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </span>
                </td>
                <td>
                  <span *ngIf="staff.status == 1" (click)="clickDisableStaff(staff)" class="ml-3 pointer">
                    <svg class="bi bi-trash" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.5 7.5A.5.5 0 018 8v6a.5.5 0 01-1 0V8a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V8a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V8z">
                      </path>
                      <path fill-rule="evenodd"
                        d="M16.5 5a1 1 0 01-1 1H15v9a2 2 0 01-2 2H7a2 2 0 01-2-2V6h-.5a1 1 0 01-1-1V4a1 1 0 011-1H8a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM6.118 6L6 6.059V15a1 1 0 001 1h6a1 1 0 001-1V6.059L13.882 6H6.118zM4.5 5V4h11v1h-11z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-end p-2">
          <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addStaffModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Nuevo Staff</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="submitAddStaff()" ngNativeValidate>
    <div class="modal-body">
      <div class="form-group">
        <label for="name">Nombre</label>
        <input id="name" type="text" class="form-control" placeholder="" name="name" [(ngModel)]="newStaff.name" required autofocus>
      </div>
      <div class="form-group">
        <label for="email">Correo electrónico</label>
        <input id="email" type="email" class="form-control" placeholder="" name="email" [(ngModel)]="newStaff.email" required>
      </div>
      <div class="form-group">
        <label for="userType">Tipo de privilegios</label>
        <select class="custom-select" id="userType" name="userType" [(ngModel)]="newStaff.userType" required>
          <option value="1">Administrador</option>
          <option value="2">Staff</option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-yellow btn-block">Guardar</button>
    </div>
  </form>
</ng-template>

<ng-template #editStaffModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Información del Staff</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="updateStaffInfo()" ngNativeValidate>
    <div class="modal-body">
      <div class="form-group">
        <label for="name">Nombre</label>
        <input id="name" type="text" class="form-control" placeholder="" name="name" [(ngModel)]="selectedStaff.name"
          required autofocus>
      </div>
      <div class="form-group">
        <label for="email">Correo electrónico</label>
        <input id="email" type="email" class="form-control" placeholder="" name="email" [(ngModel)]="selectedStaff.email"
          required>
      </div>
      <div class="form-group">
        <label for="idUserType">Tipo de privilegios</label>
        <select class="custom-select" id="idUserType" name="idUserType" [(ngModel)]="selectedStaff.idUserType" required>
          <option value="1">Administrador</option>
          <option value="2">Staff</option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-yellow btn-block">Actualizar</button>
    </div>
  </form>
</ng-template>
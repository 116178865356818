<header id="main-navbar" class="navbar navbar-expand flex-md-row bd-navbar py-0">
  <img height="80" src="../../assets/img/wmi-logo.png">
  <div class="navbar-nav-scroll">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="navbar-nav bd-navbar-nav flex-row">
      <li [ngbNavItem]="1" class="nav-item" *ngIf="idUserType == 1 || idUserType == 2">
        <a ngbNavLink class="nav-link pointer" (click)="goToLink('/app/home')" [ngClass]="{'active-nav': currentRoute.includes('/app/home') || currentRoute.includes('/app/event') || currentRoute.includes('/app/selection')}">Eventos</a>
      </li>
      <li [ngbNavItem]="2" class="nav-item" *ngIf="idUserType == 1 || idUserType == 2">
        <a ngbNavLink class="nav-link pointer" (click)="goToLink('/app/staff')" [ngClass]="{'active-nav': currentRoute.includes('/app/staff')}">Staff</a>
      </li>
      <li [ngbNavItem]="3" class="nav-item" *ngIf="idUserType == 3">
        <a ngbNavLink class="nav-link pointer" (click)="goToLink('/app/selection')" [ngClass]="{'active-nav': currentRoute.includes('/app/selection')}">Mi evento</a>
      </li>
    </ul>
  </div>


  <div ngbDropdown class="navbar-nav flex-row ml-auto d-none d-flex d-inline-block">
    <button class="btn" id="dropdownBasic1" ngbDropdownToggle>{{username}}</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-right">
      <button ngbDropdownItem (click)="logout()">Cerrar sesión</button>
    </div>
  </div>

</header>
<router-outlet></router-outlet>
import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

interface Staff {
  idUser: number;
  name: string;
  email: string;
  idUserType: number;
  createdAt: string;
  status: number;
}

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {

  @Input() newStaff = { name: null, email: null, userType: null };

  allStaff: Staff[];
  page = 1;
  pageSize = 20;
  collectionSize: number;
  selectedStaff: any;

  constructor(public api: ApiService, private modalService: NgbModal, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getStaff();
  }

  getStaff() {
    this.spinner.show();
    this.allStaff = [];
    this.api.getAllStaff().subscribe((data) => {
      if (data.meta.code == 200) {
        this.allStaff = data.data;
        this.collectionSize = this.allStaff.length;
      } else {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      }
      this.spinner.hide();
    }, (err) => {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
      localStorage.clear();
      this.router.navigate(['/']);
    });
  }

  get staffs(): Staff[] {
    return this.allStaff
      .map((staff, i) => ({ id: i + 1, ...staff }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  openAddStaffModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submitAddStaff() {
    this.spinner.show();
    let body = {
      data: {
        name: this.newStaff.name,
        email: this.newStaff.email,
        userType: this.newStaff.userType
      }
    }
    this.api.createStaff(body).subscribe((result) => {
      if (result.meta.code == 200) {
        Swal.fire('', 'El staff ha sido agregado exitosamente', 'success');
        this.modalService.dismissAll();
        this.getStaff();
      } else {
        if (result.meta.status == "Email already") {
          Swal.fire({ icon: 'error', title: 'Error', text: 'Ya existe un staff registrado con este correo electrónico' });
        } else {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        }
      }
      this.spinner.hide();
    }, (err) => {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
    });
  }

  openModalWithInfo(content, typeOfModal, selectedData) {
    if (typeOfModal == 'editStaffModal') {
      this.selectedStaff = JSON.parse(JSON.stringify(selectedData));
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  clickDisableStaff(staff) {
    let action;
    if (staff.status == 1) {
      action = "deshabilitar";
    } else {
      action = "habilitar";
    }
    Swal.fire({
      title: '',
      text: '¿Estás seguro de querer ' + action + ' a ' + staff.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        let body = {
          data: {
            idUser: staff.idUser,
            status: staff.status == 1 ? 0 : 1
          }
        }
        this.api.changeStaffAccess(body).subscribe((result) => {
          if (result.meta.code == 200) {
            const findIndex = this.allStaff.findIndex(element => element.idUser == staff.idUser);
            if (body.data.status == 1) {
              Swal.fire('', 'El staff ha sido habilitado exitosamente', 'success');
              this.allStaff[findIndex].status = 1;
            } else {
              Swal.fire({ icon: 'warning', title: '', text: 'El staff ha sido deshabilitado' });
              this.allStaff[findIndex].status = 0
            }
          } else {
            Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          }
          this.spinner.hide();
        }, (err) => {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          this.spinner.hide();
        });
      } else {
        this.spinner.hide();
      }
    })
  }

  updateStaffInfo() {
    this.spinner.show();
    let body = {
      data: {
        idUser: this.selectedStaff.idUser,
        name: this.selectedStaff.name,
        email: this.selectedStaff.email,
        userType: this.selectedStaff.idUserType,
      }
    }
    this.api.updateStaffInfo(body).subscribe((result) => {
      if (result.meta.code == 200) {
        const findIndex = this.allStaff.findIndex(element => element.idUser == this.selectedStaff.idUser);
        this.allStaff[findIndex].name = this.selectedStaff.name;
        this.allStaff[findIndex].email = this.selectedStaff.email;
        this.allStaff[findIndex].idUserType = this.selectedStaff.idUserType;
        Swal.fire('', 'La información del staff ha sido actualizada exitosamente', 'success');
        this.modalService.dismissAll();
      } else {
        if (result.meta.status == "Email already") {
          Swal.fire({ icon: 'error', title: 'Error', text: 'Ya existe un usuario/staff registrado con este correo electrónico' });
        } else {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        }
      }
      this.spinner.hide();
    }, (err) => {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
    });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2'
import { tz } from 'moment-timezone';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Input() user = { email: '', password: '' };
  currentRoute;
  showLinkConfirm: boolean = false;
  
  constructor(public api: ApiService, private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    if (this.route.routeConfig.path == "c/:c") {
      this.confirmAccount();
    }
  }

  clickSignIn() {
    this.spinner.show()
    var body = {
      data: {
        email: this.user.email,
        password: this.user.password,
        timezone: tz.guess() || null,
      }
    }
    if (body.data.email && body.data.password) {
      this.api.signin(body).subscribe((result) => {
        if (result.meta.code == 200) {
          this.spinner.hide();
          localStorage.setItem('idUser', result.data.idUser);
          localStorage.setItem('name', result.data.name);
          localStorage.setItem('email', result.data.email);
          localStorage.setItem('token', result.data.token);
          localStorage.setItem('idUserType', result.data.idUserType);
          localStorage.setItem('timezone', result.data.timezone);
          if (result.data.idUserType == 1 || result.data.idUserType == 2) {
            this.router.navigate(['/app/home']);
          } else {
            this.router.navigate(['/app/selection']);
          }
        } else {
          if (result.meta.status == "Account not confirmed") {
            Swal.fire({ icon: 'warning', title: 'La cuenta no ha sido confirmada', text: 'Haz clic en "Reenviar correo de confirmación"' });
            this.showLinkConfirm = true;
          } else if (result.meta.status == "Password doesn't match") {
            Swal.fire({ icon: 'error', title: 'Contraseña incorrecta', text: 'Vuelve a intentarlo o haz clic en Olvidaste tu contraseña para cambiarla' });
          } else {
            Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          }
          this.spinner.hide();
        }
      }, (err) => {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        this.spinner.hide();
      });
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de ingresar el correo electrónico y la contraseña' });
      this.spinner.hide();
    }
  }

  goToRecoverPassword() {
    this.router.navigate(['/password-change']);
  }

  confirmAccount() {
    this.spinner.show()
    var c = this.route.snapshot.paramMap.get("c");
    var body = {
      data: {
        c: c
      }
    }
    this.api.confirmAccount(body).subscribe((result) => {
      if (result.meta.code == 200) {
        Swal.fire('Tu cuenta ha sido confirmada correctamente', 'Ya puedes iniciar sesión.', 'success')
        this.spinner.hide()
      } else {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        this.spinner.hide()
      }
    }, (err) => {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
    });
  }


  goToResendConfirmationEmail() {
    this.router.navigate(['/confirmation']);
  }
}

<header id="main-navbar" class="navbar navbar-expand flex-md-row bd-navbar py-0">
  <img class="pointer" (click)="goToLogin()" height="80" src="../../assets/img/wmi-logo.png">
</header>

<div id="login-background">
  <div class="">
    <div class="container landing-padding-top">
      <div class="row justify-content-center">
        <div class="col-lg-6 text-center">
          <h2>Reenviar el correo de confirmación</h2>
          <br>
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="card">
                <div class="card-body">
                  <br>
                  <form (ngSubmit)="resendConfirmationEmail()" ngNativeValidate>
                    <input type="email" class="form-control" name="email" [(ngModel)]="user.email"
                      placeholder="Correo electrónico" required />
                    <br>
                    <button type="submit" class="btn btn-yellow btn-block">Reenviar correo de confirmación</button>
                    <br>
                    <a><small class="pointer" (click)="goToLogin()">Regresar a Inicio de Sesión</small></a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
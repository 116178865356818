import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  username: string;
  currentRoute: string;
  activeId;
  idUserType: number;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.username = localStorage.getItem('name');
    this.currentRoute = this.router.url;
    this.idUserType = +localStorage.getItem('idUserType');
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler($event) {
    if (localStorage.getItem("unsavedChanges") != null) {
      $event.returnValue = "¿Deseas abandonar el sitio? Es posible que los cambios que implementaste no se puedan guardar.";
    }
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler($event) {
    localStorage.removeItem("unsavedChanges");
  }

  @HostListener('window:popstate', ['$event'])
  onPopState($event) {
    localStorage.removeItem("unsavedChanges");
  }

  logout(): void {
    if (localStorage.getItem("unsavedChanges") == null) {
      localStorage.clear();
      this.router.navigate(['/']);
    } else {
      Swal.fire({
        title: '',
        text: 'Es posible que haya cambios sin guardar, ¿estás seguro de querer abandonar esta página?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Abandonar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          localStorage.clear();
          this.router.navigate(['/']);
        }
      })
    }
  }

  goToLink(link:string): void {
    if (localStorage.getItem("unsavedChanges") == null) {
      this.router.navigate([link]);
      this.currentRoute = link;
    } else {
      Swal.fire({
        title: '',
        text: 'Es posible que haya cambios sin guardar, ¿estás seguro de querer abandonar esta página?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Abandonar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("unsavedChanges");
          this.router.navigate([link]);
          this.currentRoute = link;
        }
      })
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @Input() user = { email: '' };

  constructor(public api: ApiService, private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  clickChangePassword() {
    this.spinner.show();
    var body = {
      data: {
        email: this.user.email,
      }
    }
    if (body.data.email) {
      this.api.changePassword(body).subscribe((result) => {
        if (result.meta.code == 200) {
          Swal.fire('', 'Si tu correo se encuentra registrado en We Make It, recibirás en tu correo instrucciones para recuperar tu contraseña', 'success');
          this.router.navigate(['/password-reset']);
          this.spinner.hide();
        } else {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          this.spinner.hide();
        }
      }, (err) => {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        this.spinner.hide();
      });
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de ingresar un correo electrónico' });
      this.spinner.hide();
    }
  }

  goToLogin() {
    this.router.navigate(['/']);
  }
}

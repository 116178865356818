import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import 'moment/min/locales';
moment.locale('es')
import Swal from 'sweetalert2'

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

  idEvent: number;
  event: any;
  eventUsers: any = [];
  page = 1;
  pageEventUsers = 1;
  pageSize = 10;
  collectionSize: number;
  collectionSizeEventUsers: number;
  csvFile = null;
  selectedUserEvent: any;
  selectedUserEventName: string;
  occupiedTables: number = 0;
  availableTables: number = 0;
  disabledTables: number = 0;
  disabledUsers: number = 0;

  @Input() newUser = { name: null, email: null, numberOfTables: null, date: null, time: null };
  @ViewChild('eventTabset') eventTabset;
  
  constructor(public api: ApiService, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.idEvent = +this.route.snapshot.paramMap.get("id");
    this.getEventInfo(this.idEvent);
    this.getEventUsers(this.idEvent);
  }


  getEventInfo(id: number) {
    this.event = null;
    this.api.getEventById(id).subscribe((data) => {
      if (data.meta.code == 200) {
        this.event = data.data;
        this.collectionSize = this.event.tables.length;
        var formatEventDate = this.event.event.date + ' ' + this.event.event.time;
        if (moment(formatEventDate, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment())) {
          this.event.event.active = true;
        } else {
          this.event.event.active = false;
        }
        for (var i = 0; i < this.event.tables.length; i++) {
          if (this.event.tables[i].status == 1 && this.event.tables[i].idUser == null) {
            this.availableTables += 1;
          } else if (this.event.tables[i].status == 1 && this.event.tables[i].idUser != null) {
            this.occupiedTables += 1;
          } else if (this.event.tables[i].status == 0) {
            this.disabledTables += 1;
          }
        }
      } else {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      }
      this.spinner.hide();
    }, (err) => {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
      localStorage.clear();
      this.router.navigate(['/']);
    });
  }

  get listTables(): Event[] {
    return this.event?.tables
      .map((event, i) => ({ id: i + 1, ...event }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  getEventUsers(id: number) {
    this.eventUsers = [];
    this.api.getEventUsers(id).subscribe((data) => {
      if (data.meta.code == 200) {
        this.eventUsers = data.data;
        for (var i = 0; i < this.eventUsers.length; i++) {
          if (this.eventUsers[i].status == 0) {
            this.disabledUsers += 1;
          }
        }
        this.collectionSizeEventUsers = this.eventUsers.length;
      } else {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        this.spinner.hide();
      }
    }, (err) => {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
      localStorage.clear();
      this.router.navigate(['/']);
    });
  }

  get listEventUsers(): Event[] {
    return this.eventUsers
      .map((eventUser, i) => ({ id: i + 1, ...eventUser }))
      .slice((this.pageEventUsers - 1) * this.pageSize, (this.pageEventUsers - 1) * this.pageSize + this.pageSize);
  }

  openModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  openModalWithInfo(content, typeOfModal, selectedData) {
    if (typeOfModal == 'userEventInfoModal') {
      this.selectedUserEvent = JSON.parse(JSON.stringify(selectedData));
      this.selectedUserEventName = { ...this.selectedUserEvent }.user.name;
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  onFileSelect(eventChange) {
    if (eventChange.target.files.length > 0) {
      const file = eventChange.target.files[0];
      this.csvFile = file;
    }
  }

  submitAddUsersCSV() {
    if (this.csvFile) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('file', this.csvFile);
      this.api.addUsersByCSV(formData, this.idEvent).subscribe((result) => {
        if (result.meta.code == 200) {
          Swal.fire('', 'Los usuarios han sido agregados al evento exitosamente', 'success');
          this.event.event.numberOfUsers += 1;
          this.getEventUsers(this.idEvent);
          this.modalService.dismissAll();
          this.eventTabset.select("usersTab");
        } else {
          if (result.meta.status == "User already added to event") {
            Swal.fire({ icon: 'warning', title: 'Atención', text: 'Hay un usuario que ya ha sido agregado anteriormente a este evento' });
          } if (result.meta.status == "Users not found") {
            Swal.fire({ icon: 'error', title: 'Error', text: 'No se han encontrado usuarios. Favor de revisar el archivo seleccionado.' });
          } else {
            Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          }
        }
        this.spinner.hide();
      }, (err) => {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        this.spinner.hide();
      });
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de seleccionar un archivo' });
    }
  }

  submitAddUser() {
    this.spinner.show();
    let body = {
      data: {
        name: this.newUser.name,
        email: this.newUser.email,
        numberOfTables: this.newUser.numberOfTables,
        date: this.newUser.date,
        time: this.newUser.time
      }
    }
    if (body.data.name && body.data.email && body.data.numberOfTables && body.data.date && body.data.time) {
      var formatTurn = body.data.date + ' ' + body.data.time;
      var formatEventDate = this.event.event.date + ' ' + this.event.event.time;
      if (!moment(formatTurn, "YYYY-MM-DD HH:mm", true).isValid()) {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Selecciona una fecha y hora correcta para el evento' });
        this.spinner.hide();
        return;
      }
      if (moment(formatTurn, "YYYY-MM-DD HH:mm").isSameOrAfter(moment())) {
        if (moment(formatTurn, "YYYY-MM-DD HH:mm:ss").isBefore(moment(formatEventDate, "YYYY-MM-DD HH:mm:ss"))) {
          this.api.addUserToEvent(body, this.idEvent).subscribe((result) => {
            if (result.meta.code == 200) {
              Swal.fire('', 'El usuario ha sido agregado al evento exitosamente', 'success');
              this.getEventUsers(this.idEvent);
              this.modalService.dismissAll();
              this.eventTabset.select("usersTab");
            } else {
              if (result.meta.status == "User already added to event") {
                Swal.fire({ icon: 'error', title: 'Atención', text: 'Este usuario ya ha sido agregado anteriormente a este evento' });
              } else {
                Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
              }
            }
            this.spinner.hide();
          }, (err) => {
            Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
            this.spinner.hide();
          });
        } else {
          Swal.fire({ icon: 'warning', title: 'Atención', text: "La fecha del turno de selección debe de ser una antes a la fecha del evento (" + moment(formatEventDate).format("DD-MM-YYYY HH:mm") + ")" });
          this.spinner.hide();
        }
      } else {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Se ha seleccionado una fecha pasada para el turno de selección' });
        this.spinner.hide();
      }
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de completar todos los campos' });
      this.spinner.hide();
    }
  }

  updateUserInfo() {
    this.spinner.show();
    let body = {
      data: {
        idUser: this.selectedUserEvent.idUser,
        name: this.selectedUserEvent.user.name,
        email: this.selectedUserEvent.user.email,
      }
    }
    if (body.data.idUser && body.data.name && body.data.email) {
      this.api.updateUserInfo(body).subscribe((result) => {
        if (result.meta.code == 200) {
          Swal.fire('', 'La información del usuario ha sido actualizada exitosamente', 'success');
          const findIndex = this.eventUsers.findIndex(element => element.idUser == this.selectedUserEvent.idUser);
          this.eventUsers[findIndex].user.name = this.selectedUserEvent.user.name;
          this.eventUsers[findIndex].user.email = this.selectedUserEvent.user.email;
          this.modalService.dismissAll();
        } else {
          if (result.meta.status == "Email already") {
            Swal.fire({ icon: 'error', title: 'Error', text: 'Ya existe un usuario registrado con este correo electrónico' });
          } else {
            Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          }
        }
        this.spinner.hide();
      }, (err) => {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        this.spinner.hide();
      });
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de completar todos los campos' });
      this.spinner.hide();
    }
  }

  updateUserTables() {
    this.spinner.show();
    let body = {
      data: {
        idEvent: this.selectedUserEvent.idEvent,
        idUser: this.selectedUserEvent.idUser,
        numberOfTables: this.selectedUserEvent.numberOfTables,
      }
    }
    if (body.data.idEvent && body.data.idUser && body.data.numberOfTables) {
      this.api.updateUserTables(body).subscribe((result) => {
        if (result.meta.code == 200) {
          Swal.fire('', 'El número de mesas del usuario ha sido actualizado exitosamente', 'success');
          const findIndex = this.eventUsers.findIndex(element => element.idUser == this.selectedUserEvent.idUser);
          this.eventUsers[findIndex].numberOfTables = this.selectedUserEvent.numberOfTables;
          this.modalService.dismissAll();
        } else {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        }
        this.spinner.hide();
      }, (err) => {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        this.spinner.hide();
      });
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de completar todos los campos' });
      this.spinner.hide();
    }
  }

  updateUserTurn() {
    this.spinner.show();
    let body = {
      data: {
        idUserEvent: this.selectedUserEvent.idUserEvent,
        date: this.selectedUserEvent.turn.date, // revisar formato
        time: this.selectedUserEvent.turn.time, // revisar formato
        status: this.selectedUserEvent.turn.status, // revisar 1 o 0, true o false
        finalSelectionDate: this.selectedUserEvent.turn.finalSelectionDate,
        finalSelectionTime: this.selectedUserEvent.turn.finalSelectionTime
      }
    }
    if (body.data.idUserEvent && body.data.date && body.data.time && body.data.finalSelectionDate && body.data.finalSelectionTime) {
      var formatTurn = body.data.date + ' ' + body.data.time;
      var formatFinalTurn = body.data.finalSelectionDate + ' ' + body.data.finalSelectionTime;
      var formatEventDate = this.event.event.date + ' ' + this.event.event.time;
      if (!moment(formatTurn, "YYYY-MM-DD HH:mm", true).isValid() && !moment(formatTurn, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Selecciona una fecha y hora correcta para el turno' });
        this.spinner.hide();
        return;
      }
      if (!moment(formatFinalTurn, "YYYY-MM-DD HH:mm", true).isValid() && !moment(formatFinalTurn, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Selecciona una fecha y hora correcta para el final del turno de selección' });
        this.spinner.hide();
        return;
      }
      if (moment(formatTurn, "YYYY-MM-DD HH:mm").isSameOrAfter(moment())) {
        if (moment(formatTurn, "YYYY-MM-DD HH:mm").isBefore(moment(formatEventDate, "YYYY-MM-DD HH:mm"))) {
          if (moment(formatFinalTurn, "YYYY-MM-DD HH:mm").isSameOrAfter(moment())) {
            if (moment(formatFinalTurn, "YYYY-MM-DD HH:mm").isAfter(moment(formatTurn, "YYYY-MM-DD HH:mm")) && moment(formatFinalTurn, "YYYY-MM-DD HH:mm").isBefore(moment(formatEventDate, "YYYY-MM-DD HH:mm"))) {
              this.api.updateUserTurn(body).subscribe((result) => {
                if (result.meta.code == 200) {
                  Swal.fire('', 'El turno del usuario ha sido actualizado exitosamente', 'success');
                  const findIndex = this.eventUsers.findIndex(element => element.idUserEvent == this.selectedUserEvent.idUserEvent);
                  this.eventUsers[findIndex].turn.date = this.selectedUserEvent.turn.date;
                  this.eventUsers[findIndex].turn.time = this.selectedUserEvent.turn.time;
                  this.eventUsers[findIndex].turn.status = this.selectedUserEvent.turn.status;
                  this.eventUsers[findIndex].turn.finalSelectionDate = this.selectedUserEvent.turn.finalSelectionDate;
                  this.eventUsers[findIndex].turn.finalSelectionTime = this.selectedUserEvent.turn.finalSelectionTime;
                  this.modalService.dismissAll();
                } else {
                  Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
                }
                this.spinner.hide();
              }, (err) => {
                Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
                this.spinner.hide();
              });
            } else {
              Swal.fire({ icon: 'warning', title: 'Atención', text: 'La fecha final del turno de selección debe ser mayor a la fecha del turno y antes que la fecha del evento (' + moment(formatEventDate).format("DD-MM-YYYY HH:mm") + ')' });
              this.spinner.hide();
            }
          } else {
            Swal.fire({ icon: 'warning', title: 'Atención', text: 'Se ha seleccionado una fecha pasada para el final del turno de selección' });
            this.spinner.hide();
          }
        } else {
          Swal.fire({ icon: 'warning', title: 'Atención', text: "La fecha del turno de selección debe de ser una antes a la fecha del evento (" + moment(formatEventDate).format("DD-MM-YYYY HH:mm") + ")" });
          this.spinner.hide();
        }
      } else {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Se ha seleccionado una fecha pasada para el turno de selección' });
        this.spinner.hide();
      }
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de completar todos los campos' });
      this.spinner.hide();
    }
  }

  changeTableStatus(table) {
    let action;
    if (table.status == 1) {
      action = "deshabilitar";
    } else {
      action = "habilitar";
    }
    Swal.fire({
      title: '',
      text: '¿Estás seguro de querer ' + action + ' la mesa #' + table.number + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        let body = {
          data: {
            idEvent: this.idEvent,
            idTable: table.idTable,
            status: table.status == 1 ? 0 : 1
          }
        }
        this.api.changeTableAvailability(body).subscribe((result) => {
          if (result.meta.code == 200) {
            const findIndex = this.event.tables.findIndex(element => element.idTable == table.idTable);
            if (body.data.status == 1) {
              Swal.fire('', 'La mesa ha sido habilitada exitosamente', 'success');
              this.event.tables[findIndex].status = 1;
            } else {
              Swal.fire({ icon: 'warning', title: '', text: 'La mesa ha sido deshabilitada' });
              this.event.tables[findIndex].status = 0;
            }
            this.availableTables = 0;
            this.occupiedTables = 0;
            this.disabledTables = 0;
            for (var i = 0; i < this.event.tables.length; i++) {
              if (this.event.tables[i].status == 1 && this.event.tables[i].idUser == null) {
                this.availableTables += 1;
              } else if (this.event.tables[i].status == 1 && this.event.tables[i].idUser != null) {
                this.occupiedTables += 1;
              } else if (this.event.tables[i].status == 0) {
                this.disabledTables += 1;
              }
            }
          } else {
            if (result.meta.status == "Table already selected") {
              Swal.fire({ icon: 'error', title: 'Error', text: 'Esta mesa ya ha sido seleccionada y no es posible realizar el cambio' });
            } else {
              Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
            }
          }
          this.spinner.hide();
        }, (err) => {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          this.spinner.hide();
        });
      } else {
        this.spinner.hide();
      }
    })
  }

  goToTableSelection() {
    if (this.eventUsers.length > 0) {
      this.router.navigate(['/app/selection/' + this.event.event.idEvent]);
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de primero cargar o agregar usuarios al evento' });
    }
  }
}

<div class="container">
  <br>
  <div class="row">
    <div class="card col-12">
      <div class="card-body">
        <div class="row d-flex justify-content-between">
          <div class="col-md-6">
            <h2 [ngClass]="{'d-inline-block mr-1': event?.event?.active == false || event?.event?.status == 0}">{{event?.event?.name}}</h2>
            <span *ngIf="event?.event?.active == false" class="badge badge-secondary badge-event-status mr-1">Evento pasado</span>
            <span *ngIf="event?.event?.status == 0" class="badge badge-danger badge-event-status">Evento desactivado</span>
            <p><b>Lugar:</b> {{event?.event?.place}}</p>
            <p><b>Fecha del evento:</b> {{event?.event?.date | date: 'longDate'}} @{{event?.event?.time}}</p>
            <p><b>Fin de turnos de selección:</b> {{event?.event?.finalSelectionDate | date: 'longDate' }} @{{event?.event?.finalSelectionTime}}</p>
            <p><b>Total de mesas:</b> {{event?.event?.totalTables}} {{event?.event?.totalTables > 1 ? 'mesas' : 'mesa'}}</p>
            <div>
              <button *ngIf="event?.event?.active && event?.event?.status == 1 && event?.event?.numberOfUsers == 0" type="button" class="btn btn-yellow" (click)="openModal(addUsersByCSV)">Cargar usuarios</button>
              <button *ngIf="event?.event?.active && event?.event?.status == 1 && event?.event?.numberOfUsers > 0" type="button" class="btn btn-yellow" (click)="openModal(addUserIndividual)">Agregar usuario</button>
              <button type="button" [appExport]="event?.tables" fileName="{{event?.event?.name}}" eventDate="{{event?.event?.date}}" [eventUsers]="eventUsers" class="btn btn-info" [ngClass]="event?.event?.active && event?.event?.status == 1 ? 'ml-3 btn-export-margin': 'ml-0'">
                Exportar lista
              </button>
              <button *ngIf="event?.event?.active && event?.event?.status == 1" type="button" class="btn btn-success btn-table-selection-margin" (click)="goToTableSelection()">
                Selección de mesas
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <img src="{{event?.event?.layout}}" width="100%">
          </div>
        </div>
        <ngb-tabset #eventTabset="ngbTabset">
          <ngb-tab id="tablesTab" title="Mesas">
            <ng-template ngbTabContent>
              <br>
              <div class="row d-flex justify-content-between m-0">
                <h4>Lista de mesas</h4>
                <div>
                  <span class="badge badge-primary ml-1">{{event?.tables?.length}} Total</span>
                  <span class="badge badge-success ml-1">{{availableTables}} Disponibles</span>
                  <span class="badge badge-warning ml-1">{{occupiedTables}} Ocupadas</span>
                  <span *ngIf="disabledTables > 0" class="badge badge-danger ml-1">{{disabledTables}} Deshabilitadas</span>
                </div>
              </div>
              <div class="table-wrapper">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 10%"># Mesa</th>
                      <th scope="col" style="width: 30%;">Invitado</th>
                      <th scope="col" style="width: 40%;">Comentarios</th>
                      <th scope="col" style="width: 20%">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let table of listTables">
                      <th scope="row">{{table.number }}</th>
                      <td>{{table.name || 'S/A'}}</td>
                      <td>{{table.comments}}</td>
                      <td>
                        <span *ngIf="table.status == 1 && table.idUser == null" class="badge badge-success">Disponible</span>
                        <span *ngIf="table.status == 1 && table.idUser != null" class="badge badge-warning">Ocupada</span>
                        <span *ngIf="table.status == 0" class="badge badge-danger">Deshabilitada</span>
                        <span *ngIf="event?.event?.active && event?.event?.status == 1 && ((table.status == 1 && table.idUser == null) || table.status == 0)" class="ml-3 pointer"
                          (click)="changeTableStatus(table)">
                          <svg class="bi bi-arrow-repeat" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                              d="M4 9.5a.5.5 0 00-.5.5 6.5 6.5 0 0012.13 3.25.5.5 0 00-.866-.5A5.5 5.5 0 014.5 10a.5.5 0 00-.5-.5z"
                              clip-rule="evenodd"></path>
                            <path fill-rule="evenodd"
                              d="M4.354 9.146a.5.5 0 00-.708 0l-2 2a.5.5 0 00.708.708L4 10.207l1.646 1.647a.5.5 0 00.708-.708l-2-2zM15.947 10.5a.5.5 0 00.5-.5 6.5 6.5 0 00-12.13-3.25.5.5 0 10.866.5A5.5 5.5 0 0115.448 10a.5.5 0 00.5.5z"
                              clip-rule="evenodd"></path>
                            <path fill-rule="evenodd"
                              d="M18.354 8.146a.5.5 0 00-.708 0L16 9.793l-1.646-1.647a.5.5 0 00-.708.708l2 2a.5.5 0 00.708 0l2-2a.5.5 0 000-.708z"
                              clip-rule="evenodd"></path>
                          </svg>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex p-2 justify-content-end">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
                </ngb-pagination>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab id="usersTab" title="Usuarios">
            <ng-template ngbTabContent>
              <br>
              <div class="row d-flex justify-content-between m-0">
                <h4>Lista de usuarios</h4>
                <div>
                  <span class="badge badge-primary ml-1">{{eventUsers?.length}} Total</span>
                  <span *ngIf="disabledUsers > 0" class="badge badge-danger ml-1">{{disabledUsers}} Desactivados</span>
                </div>
              </div>
              <div class="table-wrapper">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Correo</th>
                      <th scope="col" style="width: 10%"># Mesas</th>
                      <th scope="col">Turno</th>
                      <th scope="col">Fin del Turno</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let userEvent of listEventUsers" [ngClass]="{'pointer': event?.event?.active && event?.event?.status == 1}"
                      (click)="event?.event?.active && event?.event?.status == 1 && openModalWithInfo(userEventInfoModal, 'userEventInfoModal', userEvent)">
                      <th scope="row">{{userEvent?.user?.name }}</th>
                      <td>{{userEvent?.user?.email}}</td>
                      <td>{{userEvent?.numberOfTables}}</td>
                      <td>{{userEvent?.turn?.date | date }} {{userEvent?.turn?.time}}</td>
                      <td>{{userEvent?.turn?.finalSelectionDate | date }} {{userEvent?.turn?.finalSelectionTime}}</td>
                      <td>
                        <span *ngIf="userEvent?.status == 1" class="badge badge-success">Activado</span>
                        <span *ngIf="userEvent?.status == 0" class="badge badge-danger">Desactivado</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex p-2 justify-content-end">
                <ngb-pagination [collectionSize]="collectionSizeEventUsers" [(page)]="pageEventUsers" [pageSize]="pageSize">
                </ngb-pagination>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
        
      </div>
    </div>
  </div>
</div>


<ng-template #addUsersByCSV let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cargar usuarios por CSV</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="submitAddUsersCSV()">
    <div class="modal-body">
      <div class="form-group">
        <a href="/assets/docs/CARGAR USUARIOS TEMPLATE.csv" target="_self" download="CARGAR USUARIOS TEMPLATE.csv">
          <span style="font-size: 2em;">
            <svg class="bi bi-file-earmark-arrow-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4 1h5v1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6h1v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z" />
              <path d="M9 4.5V1l5 5h-3.5A1.5 1.5 0 0 1 9 4.5z" />
              <path fill-rule="evenodd"
                d="M5.646 9.146a.5.5 0 0 1 .708 0L8 10.793l1.646-1.647a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 0-.708z" />
              <path fill-rule="evenodd" d="M8 6a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4A.5.5 0 0 1 8 6z" />
            </svg>
          </span>Descargar Template
        </a>
        <br><br>
        <label for="name">Archivo CSV de usuarios</label><br>
        <button type="button" class="btn btn-yellow" (click)="fileInput.click()">
          <span>Seleccionar archivo</span>
          <input #fileInput type="file" (change)="onFileSelect($event)" style="display:none;" accept=".csv" required/>
        </button>
        <span> {{csvFile?.name}}</span>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-yellow btn-block">Enviar</button>
    </div>
  </form>
</ng-template>


<ng-template #addUserIndividual let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Agregar usuario al evento</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="submitAddUser()" ngNativeValidate>
    <div class="modal-body">
      <div class="form-group">
        <label for="name">Nombre</label>
        <input id="name" type="text" class="form-control" name="name" [(ngModel)]="newUser.name" required />
      </div>
      <div class="form-group">
        <label for="email">Correo electrónico</label>
        <input id="email" type="email" class="form-control" name="email" [(ngModel)]="newUser.email" required />
      </div>
      <div class="form-group">
        <label for="numberOfTables">Número de mesas</label>
        <input id="numberOfTables" type="number" class="form-control" name="totalTables" [(ngModel)]="newUser.numberOfTables" required />
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="date">Fecha de turno</label>
            <input id="date" type="date" class="form-control" [(ngModel)]="newUser.date" name="date" required />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="time">Hora de turno</label>
            <input id="time" type="time" class="form-control" [(ngModel)]="newUser.time" name="time" required />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-yellow btn-block">Guardar</button>
    </div>
  </form>
</ng-template>


<ng-template #userEventInfoModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{selectedUserEventName}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <ul ngbNav #navEditUserEvent="ngbNav" class="nav-tabs">
      <li ngbNavItem="userInfoTab">
        <a ngbNavLink>Información</a>
        <ng-template ngbNavContent>
          <form (ngSubmit)="updateUserInfo()" ngNativeValidate>
            <div class="form-group">
              <label for="name">Nombre</label>
              <input id="name" type="text" class="form-control" name="name" [(ngModel)]="selectedUserEvent.user.name" required />
            </div>
            <div class="form-group">
              <label for="email">Correo electrónico</label>
              <input id="email" type="email" class="form-control" name="email" [(ngModel)]="selectedUserEvent.user.email"
                required />
            </div>
            <button type="submit" class="btn btn-yellow btn-block">Actualizar</button>
          </form>
        </ng-template>
      </li>
      <li ngbNavItem="userTablesTab">
        <a ngbNavLink>Mesas</a>
        <ng-template ngbNavContent>
          <form (ngSubmit)="updateUserTables()">
            <label for="numberOfTables">Número de mesas</label>
            <div class="row">
              <div class="col-6">
                <input id="numberOfTables" type="number" class="form-control" name="totalTables"
                  [(ngModel)]="selectedUserEvent.numberOfTables" required />
              </div>
              <div class="col-6">
                <button type="submit" class="btn btn-yellow btn-block">Actualizar</button>
              </div>
            </div>
          </form>
        </ng-template>
      </li>
      <li ngbNavItem="userTurnTab">
        <a ngbNavLink>Turno de selección</a>
        <ng-template ngbNavContent>
          <form (ngSubmit)="updateUserTurn()">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="date">Fecha de turno</label>
                  <input id="date" type="date" class="form-control" [(ngModel)]="selectedUserEvent.turn.date" name="date"
                    required />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="time">Hora de turno</label>
                  <input id="time" type="time" class="form-control" [(ngModel)]="selectedUserEvent.turn.time" name="time"
                    required />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="finalSelectionDate">Fecha final de turno</label>
                  <input id="finalSelectionDate" type="date" class="form-control"
                    [(ngModel)]="selectedUserEvent.turn.finalSelectionDate" name="finalSelectionDate" required />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="finalSelectionTime">Hora final de turno</label>
                  <input id="finalSelectionTime" type="time" class="form-control"
                    [(ngModel)]="selectedUserEvent.turn.finalSelectionTime" name="finalSelectionTime" required />
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-yellow btn-block">Actualizar</button>
          </form>
        </ng-template>
      </li>
    </ul>
    <br>
    <div [ngbNavOutlet]="navEditUserEvent"></div>
  </div>
</ng-template>
<header id="main-navbar" class="navbar navbar-expand flex-md-row bd-navbar py-0">
  <img class="pointer" (click)="goToLogin()" height="80" src="../../assets/img/wmi-logo.png">
</header>

<div class="container">
  <div class="row d-flex justify-content-center">
    <div class="col-10 text-center">
      <div class="margin-turn-section" *ngIf="!showUnsubscribed && !showError">
        <span>¿Realmente quieres cancelar toda la comunicación para el siguiente correo?</span><br>
        <span>{{email}}</span>
        <hr>
        <div class="swal2-actions">
          <button type="button" (click)="unsubscribe()" class="btn btn-secondary m-1" aria-label="">Sí, cancelar comunicación</button>
          <button type="button" class="btn btn-secondary m-1" (click)="goToLogin()" aria-label="">No, regresar</button>
        </div>
      </div>
      <div class="margin-turn-section" *ngIf="showUnsubscribed || showError">
        <span *ngIf="showUnsubscribed">Su solicitud de cancelación ha sido procesada</span>
        <span *ngIf="showError">Lo sentimos, ha ocurrido un error en la cancelación. Favor de contactar al equipo de We Make It.</span>
      </div>
    </div>
  </div>
</div>
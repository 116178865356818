import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

//const endpoint = 'http://localhost:3001/';
//const endpoint = 'https://wemakeit-api-01.herokuapp.com/';
//const endpoint = 'http://ec2-100-25-102-153.compute-1.amazonaws.com/api/';
const endpoint = 'https://app.wemakeitmx.com/api/';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable()
export class ApiService {

  constructor(private http: HttpClient, private router: Router) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure

      // TODO: better job of transforming error for user consumption
      localStorage.clear();
      this.router.navigate(['/']);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  
  signin(data): Observable<any> {
    return this.http.post<any>(endpoint + 'signin', JSON.stringify(data), httpOptions).pipe(
      catchError(this.handleError<any>('signin'))
    );
  }

  changePassword(data): Observable<any> {
    return this.http.post<any>(endpoint + 'changepassword', JSON.stringify(data), httpOptions).pipe(
      catchError(this.handleError<any>('changepassword'))
    );
  }

  resetPassword(data): Observable<any> {
    return this.http.post<any>(endpoint + 'resetpassword', JSON.stringify(data), httpOptions).pipe(
      catchError(this.handleError<any>('resetpassword'))
    );
  }

  getAllEvents(): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(endpoint + 'event', httpOptionsAuth).pipe(
      map(this.extractData));
  }

  getEventById(id): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(endpoint + 'event/' + id, httpOptionsAuth).pipe(
      map(this.extractData));
  }

  getAllStaff(): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(endpoint + 'staff', httpOptionsAuth).pipe(
      map(this.extractData));
  }

  createStaff(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.post<any>(endpoint + 'signup', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('createStaff'))
    );
  }

  createEvent(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.post<any>(endpoint + 'event', data, httpOptionsAuth).pipe(
      catchError(this.handleError<any>('createEvent'))
    );
  }

  addUsersByCSV(data, idEvent): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.post<any>(endpoint + 'event/' + idEvent + '/user/batch', data, httpOptionsAuth).pipe(
      catchError(this.handleError<any>('addUsersByCSV'))
    );
  }

  addUserToEvent(data, idEvent): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.post<any>(endpoint + 'event/' + idEvent + '/user', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('addUserToEvent'))
    );
  }

  getEventUsers(id): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(endpoint + 'event/' + id + '/user', httpOptionsAuth).pipe(
      map(this.extractData));
  }

  updateUserInfo(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'user', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('updateUserInfo'))
    );
  }

  updateUserTables(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'event/user/tables', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('updateUserTables'))
    );
  }

  updateUserTurn(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'user/turn', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('updateUserTurn'))
    );
  }

  changeTableAvailability(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'event/table/available', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('changeTableAvailability'))
    );
  }

  getUserTurn(id): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(endpoint + 'user/turn/' + id, httpOptionsAuth).pipe(
      map(this.extractData));
  }

  submitTableSelection(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'event/table/select', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('submitTableSelection'))
    );
  }

  editEvent(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'event', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('editEvent'))
    );
  }
  
  changeEventAvailability(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'event/available', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('changeEventAvailability'))
    );
  }

  updateEventInfo(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'event', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('updateEventInfo'))
    );
  }

  updateEventFinalTurn(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'event/turn', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('updateEventFinalTurn'))
    );
  }

  changeStaffAccess(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'staff/access', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('changeStaffAccess'))
    );
  }

  updateStaffInfo(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'staff', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('updateStaffInfo'))
    );
  }

  updateTableComments(data): Observable<any> {
    const httpOptionsAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.put<any>(endpoint + 'event/table/comments', JSON.stringify(data), httpOptionsAuth).pipe(
      catchError(this.handleError<any>('updateTableComments'))
    );
  }

  confirmAccount(data): Observable<any> {
    return this.http.post<any>(endpoint + 'confirm', JSON.stringify(data), httpOptions).pipe(
      catchError(this.handleError<any>('confirmAccount'))
    );
  }

  resendConfirmationEmail(data): Observable<any> {
    return this.http.post<any>(endpoint + 'confirm/email', JSON.stringify(data), httpOptions).pipe(
      catchError(this.handleError<any>('resendConfirmationEmail'))
    );
  }

  unsubscribe(data): Observable<any> {
    return this.http.post<any>(endpoint + 'unsubscribe', JSON.stringify(data), httpOptions).pipe(
      catchError(this.handleError<any>('unsubscribe'))
    );
  }
}
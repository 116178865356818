import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-resend-confirmation',
  templateUrl: './resend-confirmation.component.html',
  styleUrls: ['./resend-confirmation.component.css']
})
export class ResendConfirmationComponent implements OnInit {

  @Input() user = { email: '' };

  constructor(public api: ApiService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  resendConfirmationEmail() {
    this.spinner.show();
    var body = {
      data: {
        email: this.user.email,
      }
    }
    if (body.data.email) {
      this.api.resendConfirmationEmail(body).subscribe((result) => {
        if (result.meta.code == 200) {
          Swal.fire('', 'Si tu correo se encuentra registrado en We Make It, recibirás en tu correo instrucciones para confirmar tu cuenta', 'success');
          this.spinner.hide();
        } else {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo o contactar a los organizadores' });
          this.spinner.hide();
        }
      }, (err) => {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo o contactar a los organizadores' });
        this.spinner.hide();
      });
    } else {
      Swal.fire({ icon: 'warning', title: 'Ha ocurrido un error', text: 'Favor de ingresar un correo electrónico' });
      this.spinner.hide();
    }
  }

  goToLogin() {
    this.router.navigate(['/']);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import 'moment/min/locales';
moment.locale('es');
import Swal from 'sweetalert2'

interface Event {
  idEvent: number;
  name: string;
  time: string;
  date: string;
  place: string;
  totalTables: number;
  idLayout: number;
  finalSelectionDate: string;
  finalSelectionTime: string;
  createdAt: string;
  status: number;
  active: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  
  pastEvents: Event[];
  currentEvents: Event[];
  idUserType: number;
  page = 1;
  page2 = 1;
  pageSize = 6;
  pageSize2 = 6;
  collectionSize: number;
  collectionSizePast: number;
  createEventForm: FormGroup;  
  @Input() newEvent = { name: null, date: null, time: null, place: null, totalTables: null, finalSelectionDate: null, finalSelectionTime: null};
  layoutImage = null;
  selectedEvent: any;
  selectedEventName: string;

  constructor(public api: ApiService, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.idUserType = +localStorage.getItem('idUserType');
    if (this.idUserType == 1) {
      this.getEvents();
    }
    this.createEventForm = this.formBuilder.group({
      name: this.newEvent.name,
      date: this.newEvent.date,
      time: this.newEvent.time,
      place: this.newEvent.place,
      totalTables: this.newEvent.totalTables,
      finalSelectionDate: this.newEvent.finalSelectionDate,
      finalSelectionTime: this.newEvent.finalSelectionTime
    });
  }

  getEvents() {
    this.currentEvents = [];
    this.pastEvents = [];
    this.api.getAllEvents().subscribe((data) => {
      if (data.meta.code == 200) {
        for (var i = 0; i < data.data.length; i++) {
          var formatEventDate = data.data[i].date + ' ' + data.data[i].time;
          if (data.data[i].status != 2 && moment(formatEventDate, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment())) {
            data.data[i].active = true;
            this.currentEvents.push(data.data[i]);
          } else {
            data.data[i].active = false;
            this.pastEvents.push(data.data[i]);
          }
        }
        this.collectionSize = this.currentEvents.length;
        this.collectionSizePast = this.pastEvents.length;
      } else {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      }
      this.spinner.hide();
    }, (err) => {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
      localStorage.clear();
      this.router.navigate(['/']);
    });
  }

  get events(): Event[] {
    return this.currentEvents
      .map((event, i) => ({ id: i + 1, ...event }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  get pastEventsList(): Event[] {
    return this.pastEvents
      .map((event, i) => ({ id: i + 1, ...event }))
      .slice((this.page2 - 1) * this.pageSize2, (this.page2 - 1) * this.pageSize2 + this.pageSize2);
  }

  goToEvent(idEvent: number) {
    this.router.navigate(['/app/event/' + idEvent]);
  }

  openCreateEventModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submitCreateEvent() {
    this.spinner.show();
    let body = {
      name: this.createEventForm.get('name').value,
      date: this.createEventForm.get('date').value,
      time: this.createEventForm.get('time').value,
      place: this.createEventForm.get('place').value,
      totalTables: this.createEventForm.get('totalTables').value,
      finalSelectionDate: this.createEventForm.get('finalSelectionDate').value,
      finalSelectionTime: this.createEventForm.get('finalSelectionTime').value
    }
    if (body.name && body.date && body.time && body.place && body.totalTables && body.finalSelectionDate && body.finalSelectionTime && this.layoutImage) {
      var formatDate = body.date + ' ' + body.time;
      var formatFinalDate = body.finalSelectionDate + ' ' + body.finalSelectionTime;
      if (!moment(formatDate, "YYYY-MM-DD HH:mm", true).isValid()) {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Selecciona una fecha y hora correcta para el evento' });
        this.spinner.hide();
        return;
      }
      if (!moment(formatFinalDate, "YYYY-MM-DD HH:mm", true).isValid()) {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Selecciona una fecha y hora correcta para el final del turno de selección' });
        this.spinner.hide();
        return;
      }
      if (moment(formatDate, "YYYY-MM-DD HH:mm").isSameOrAfter(moment())) {
        if (moment(formatFinalDate, "YYYY-MM-DD HH:mm").isAfter(moment()) ) {
          if (moment(formatFinalDate, "YYYY-MM-DD HH:mm").isBefore(moment(formatDate, "YYYY-MM-DD HH:mm"))) {
            let formData = new FormData();
            formData.append('file', this.layoutImage);
            formData.append('data', JSON.stringify(body));
            this.api.createEvent(formData).subscribe((result) => {
              if (result.meta.code == 200) {
                Swal.fire('', 'El evento ha sido creado exitosamente', 'success');
                this.modalService.dismissAll();
                this.router.navigate(['/app/event/' + result.data]);
              } else {
                Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
              }
              this.spinner.hide();
            }, (err) => {
              Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
              this.spinner.hide();
            });
          } else {
            Swal.fire({ icon: 'warning', title: 'Atención', text: 'La fecha final de los turnos de selección debe de ser una antes del evento' });
            this.spinner.hide();
          }
        } else {
          Swal.fire({ icon: 'warning', title: 'Atención', text: 'Se ha seleccionado una fecha pasada para el final del turno de selección.' });
          this.spinner.hide();
        }
      } else {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Se ha seleccionado una fecha pasada para el evento' });
        this.spinner.hide();
      }
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de completar todos los campos' });
      this.spinner.hide();
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.layoutImage = file;
    }
  }

  clickDisableEvent(event) {
    let action;
    if (event.status == 1) {
      action = "deshabilitar";
    } else {
      action = "habilitar";
    }
    Swal.fire({
      title: '',
      text: '¿Estás seguro de querer ' + action + ' el evento ' + event.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        let body = {
          data: {
            idEvent: event.idEvent,
            status: event.status == 1 ? 0 : 1
          }
        }
        this.api.changeEventAvailability(body).subscribe((result) => {
          if (result.meta.code == 200) {
            const findIndex = this.currentEvents.findIndex(element => element.idEvent == event.idEvent);
            if (body.data.status == 1) {
              Swal.fire('', 'El evento ha sido habilitado exitosamente', 'success');
              this.currentEvents[findIndex].status = 1;
            } else {
              Swal.fire({ icon: 'warning', title: '', text: 'El evento ha sido deshabilitado' });
              this.currentEvents[findIndex].status = 0
            }
          } else {
            Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          }
          this.spinner.hide();
        }, (err) => {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          this.spinner.hide();
        });
      } else {
        this.spinner.hide();
      }
    })
  }

  openModalWithInfo(content, typeOfModal, selectedData) {
    if (typeOfModal == 'eventInfoModal') {
      this.selectedEvent = JSON.parse(JSON.stringify(selectedData));
      this.selectedEventName = { ...this.selectedEvent }.name;
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  updateEventInfo() {
    this.spinner.show();
    let body = {
      data: {
        idEvent: this.selectedEvent.idEvent,
        name: this.selectedEvent.name,
        place: this.selectedEvent.place,
        date: this.selectedEvent.date,
        time: this.selectedEvent.time,
        totalTables: this.selectedEvent.totalTables
      }
    }
    if (body.data.idEvent && body.data.name && body.data.place && body.data.date && body.data.time && body.data.totalTables) {
      var formatDate = body.data.date + ' ' + body.data.time;
      if (!moment(formatDate, "YYYY-MM-DD HH:mm", true).isValid() && !moment(formatDate, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Selecciona una fecha y hora correcta para el evento' });
        this.spinner.hide();
        return;
      }
      if (moment(formatDate, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment())) {
        this.api.updateEventInfo(body).subscribe((result) => {
          if (result.meta.code == 200) {
            const findIndex = this.currentEvents.findIndex(element => element.idEvent == this.selectedEvent.idEvent);
            this.currentEvents[findIndex].name = this.selectedEvent.name;
            this.currentEvents[findIndex].place = this.selectedEvent.place;
            this.currentEvents[findIndex].date = this.selectedEvent.date;
            this.currentEvents[findIndex].time = this.selectedEvent.time;
            this.currentEvents[findIndex].totalTables = this.selectedEvent.totalTables;
            Swal.fire('', 'La información del evento ha sido actualizada exitosamente', 'success');
            this.modalService.dismissAll();
          } else {
            if (result.meta.status == "There are tables already assigned") {
              Swal.fire({ icon: 'error', title: 'Error', text: 'La cantidad de mesas no puede ser modificada, ya hay mesas asignadas a usuarios' });
            } else {
              Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
            }
          }
          this.spinner.hide();
        }, (err) => {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          this.spinner.hide();
        });
      } else {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Se ha seleccionado una fecha pasada para el evento' });
        this.spinner.hide();
      }
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de completar todos los campos' });
      this.spinner.hide();
    }
  }

  updateEventFinalTurn() {
    this.spinner.show();
    let body = {
      data: {
        idEvent: this.selectedEvent.idEvent,
        finalSelectionDate: this.selectedEvent.finalSelectionDate,
        finalSelectionTime: this.selectedEvent.finalSelectionTime,
      }
    }
    if (body.data.idEvent && body.data.finalSelectionDate && body.data.finalSelectionTime) {
      var formatEventDate = this.selectedEvent.date + ' ' + this.selectedEvent.time;
      var formatFinalDate = body.data.finalSelectionDate + ' ' + body.data.finalSelectionTime;
      if (!moment(formatFinalDate, "YYYY-MM-DD HH:mm", true).isValid() && !moment(formatFinalDate, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Selecciona una fecha y hora correcta para el final del turno de selección' });
        this.spinner.hide();
        return;
      }
      if (moment(formatFinalDate, "YYYY-MM-DD HH:mm:ss").isAfter(moment())) {
        if (moment(formatFinalDate, "YYYY-MM-DD HH:mm:ss").isBefore(moment(formatEventDate, "YYYY-MM-DD HH:mm:ss"))) {
          this.api.updateEventFinalTurn(body).subscribe((result) => {
            if (result.meta.code == 200) {
              const findIndex = this.currentEvents.findIndex(element => element.idEvent == this.selectedEvent.idEvent);
              this.currentEvents[findIndex].finalSelectionDate = this.selectedEvent.finalSelectionDate;
              this.currentEvents[findIndex].finalSelectionTime = this.selectedEvent.finalSelectionTime;
              Swal.fire('', 'La fecha y hora del turno final para seleccionar mesas han sido actualizadas exitosamente', 'success');
              this.modalService.dismissAll();
            } else {
              Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
            }
            this.spinner.hide();
          }, (err) => {
            Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
            this.spinner.hide();
          });
        } else {
          Swal.fire({ icon: 'warning', title: 'Atención', text: 'La fecha final de los turnos de selección debe de ser una antes del evento' });
          this.spinner.hide();
        }
      } else {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Se ha seleccionado una fecha pasada para el final de los turnos de selección' });
        this.spinner.hide();
      }
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de completar todos los campos' });
      this.spinner.hide();
    }
  }
}

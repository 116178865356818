<header id="main-navbar" class="navbar navbar-expand flex-md-row bd-navbar py-0">
  <img class="pointer" (click)="goToLogin()" height="80" src="../../assets/img/wmi-logo.png">
</header>

<div id="login-background">
  <div class="">
    <div class="container landing-padding-top">
      <div class="row justify-content-center">
        <div class="col-lg-6 text-center">
          <h2>Ingresa tu nueva contraseña</h2>
          <br>
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="card">
                <div class="card-body">
                  <br>
                  <form (ngSubmit)="clickResetPassword()" ngNativeValidate>
                    <input type="text" class="form-control" name="recovery" [(ngModel)]="user.recovery"
                      placeholder="Código de recuperación" required/>
                    <br>
                    <input type="password" class="form-control" name="newPassword" [(ngModel)]="user.newPassword"
                      placeholder="Nueva contraseña" required/>
                    <br>
                    <input type="password" class="form-control" name="confirmPassword" [(ngModel)]="user.confirmPassword"
                      placeholder="Confirmar contraseña" required/>
                    <br>
                    <button type="submit" class="btn btn-yellow btn-block">Cambiar contraseña</button>
                    <br>
                    <a><small class="pointer" (click)="goToLogin()">Regresar a Inicio de Sesión</small></a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
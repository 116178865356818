import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  @Input() user = { recovery: '', newPassword: '', confirmPassword: '' };
  
  constructor(public api: ApiService, private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  clickResetPassword() {
    this.spinner.show();
    let body = {
      data: {
        recovery: this.user.recovery,
        newPassword: this.user.newPassword,
        confirmPassword: this.user.confirmPassword
      }
    }
    if (body.data.recovery && body.data.newPassword && body.data.confirmPassword) {
      if (body.data.newPassword == body.data.confirmPassword) {
        this.api.resetPassword(body).subscribe((result) => {
          if (result.meta.code == 200) {
            Swal.fire('', 'Tu contraseña ha sido cambiada exitosamente', 'success');
            this.router.navigate(['/login']);
            this.spinner.hide();
          } else {
            if (result.meta.status == "Passwords do not match") {
              Swal.fire({ icon: 'error', title: 'Error', text: 'Las nueva contraseña y la confirmación de contraseña no coinciden, favor de revisarlas y volver a intentar' });
            } else if (result.meta.status == "Expired token") {
              Swal.fire({ icon: 'error', title: 'Error', text: 'El token ha expirado. Favor de volver a mandar el correo de recuperación' });
            } else {
              Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
            }
            this.spinner.hide();
          }
        }, (err) => {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          this.spinner.hide();
        });
      } else {
        Swal.fire({ icon: 'error', title: 'Atención', text: 'Las nueva contraseña y la confirmación de contraseña no coinciden, favor de revisarlas y volver a intentar' });
        this.spinner.hide();
      }
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de completar todos los campos' });
      this.spinner.hide();
    }
  }

  goToLogin() {
    this.router.navigate(['/']);
  }
}

<div class="container">
  <br>
  <div class="row">
    <div class="card col-12">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h2>Próximos eventos</h2>
          <button type="button" class="btn btn-yellow justify-content-end" (click)="openCreateEventModal(createEventModal)">Nuevo Evento</button>
        </div>
        <br>
        <div class="table-wrapper">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Fecha</th>
                <th scope="col">Lugar</th>
                <th scope="col"># Mesas</th>
                <th scope="col">Fin de turnos de selección</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let event of events" class="pointer" (click)="goToEvent(event.idEvent)">
                <th scope="row">{{event.name }}</th>
                <td>{{event.date | date: 'longDate'}} {{event.time}}</td>
                <td>{{event.place}}</td>
                <td>{{event.totalTables | number }}</td>
                <td>{{event.finalSelectionDate | date: 'longDate' }} {{event.finalSelectionTime}}</td>
                <td>
                  <span *ngIf="event.status == 1" class="badge badge-success">Activado</span>
                  <span *ngIf="event.status == 0" class="badge badge-danger">Desactivado</span>
                </td>
                <td>
                  <span *ngIf="event.status == 1" (click)="openModalWithInfo(eventInfoModal, 'eventInfoModal', event)">
                    <svg class="bi bi-pencil" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M13.293 3.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM14 4l2 2-9 9-3 1 1-3 9-9z" clip-rule="evenodd"></path>
                      <path fill-rule="evenodd" d="M14.146 8.354l-2.5-2.5.708-.708 2.5 2.5-.708.708zM5 12v.5a.5.5 0 00.5.5H6v.5a.5.5 0 00.5.5H7v.5a.5.5 0 00.5.5H8v-1.5a.5.5 0 00-.5-.5H7v-.5a.5.5 0 00-.5-.5H5z" clip-rule="evenodd"></path>
                    </svg>
                  </span>
                  <span *ngIf="event.status == 0" (click)="clickDisableEvent(event)">
                    <svg class="bi bi-toggle-off" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M13 6a4 4 0 010 8h-3a4.992 4.992 0 002-4 4.992 4.992 0 00-2-4h3zm-6 8a4 4 0 110-8 4 4 0 010 8zm-5-4a5 5 0 005 5h6a5 5 0 000-10H7a5 5 0 00-5 5z" clip-rule="evenodd"></path>
                    </svg>
                  </span>
                </td>
                <td>
                  <span *ngIf="event.status == 1" (click)="clickDisableEvent(event)">
                    <svg class="bi bi-trash" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.5 7.5A.5.5 0 018 8v6a.5.5 0 01-1 0V8a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V8a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V8z">
                      </path>
                      <path fill-rule="evenodd"
                        d="M16.5 5a1 1 0 01-1 1H15v9a2 2 0 01-2 2H7a2 2 0 01-2-2V6h-.5a1 1 0 01-1-1V4a1 1 0 011-1H8a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM6.118 6L6 6.059V15a1 1 0 001 1h6a1 1 0 001-1V6.059L13.882 6H6.118zM4.5 5V4h11v1h-11z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-end p-2">
          <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="card col-12">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h2>Eventos pasados</h2>
        </div>
        <br>
        <div class="table-wrapper">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Fecha</th>
                <th scope="col">Lugar</th>
                <th scope="col"># Mesas</th>
                <th scope="col">Fin de turnos de selección</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let event of pastEventsList" class="pointer" (click)="goToEvent(event.idEvent)">
                <th scope="row">{{event.name }}</th>
                <td>{{event.date | date: 'longDate'}} {{event.time}}</td>
                <td>{{event.place}}</td>
                <td>{{event.totalTables | number }}</td>
                <td>{{event.finalSelectionDate | date: 'longDate' }} {{event.finalSelectionTime}}</td>
                <td>
                  <span *ngIf="event.active == false || event.status == 2" class="badge badge-secondary mr-1">Pasado</span>
                  <span *ngIf="event.status == 0" class="badge badge-danger">Desactivado</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-end p-2">
          <ngb-pagination [collectionSize]="collectionSizePast" [(page)]="page2" [pageSize]="pageSize2">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #createEventModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Nuevo Evento</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="createEventForm" (ngSubmit)="submitCreateEvent()" ngNativeValidate>
    <div class="modal-body">
      <div class="form-group">
        <label for="name">Nombre</label>
        <input id="name" type="text" class="form-control" formControlName="name" name="name" required autofocus>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="date">Fecha</label>
            <input id="date" type="date" class="form-control" formControlName="date" name="date" required>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="time">Hora</label>
            <input id="time" type="time" class="form-control" formControlName="time" name="time" required>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="place">Lugar</label>
        <input id="place" type="text" class="form-control" formControlName="place" name="place" required>
      </div>
      <div class="form-group">
        <label for="totalTables">Número de mesas</label>
        <input id="totalTables" type="number" min="1" class="form-control" formControlName="totalTables" name="totalTables" required>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="finalSelectionDate">Fecha final para seleccionar de mesas</label>
            <input id="finalSelectionDate" type="date" class="form-control" formControlName="finalSelectionDate" name="finalSelectionDate" required>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="finalSelectionTime">Hora final para seleccionar mesas</label>
            <input id="finalSelectionTime" type="time" class="form-control" formControlName="finalSelectionTime" name="finalSelectionTime" required>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="image">Mapa de mesas</label><br>
        <input type="file" name="image" (change)="onFileSelect($event)" accept="image/jpeg, image/png" required/>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-yellow btn-block">Guardar</button>
    </div>
  </form>
</ng-template>

<ng-template #eventInfoModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{selectedEventName}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ul ngbNav #navEditEvent="ngbNav" class="nav-tabs">
      <li ngbNavItem="detailsTab">
        <a ngbNavLink>Detalles del evento</a>
        <ng-template ngbNavContent>
          <form (ngSubmit)="updateEventInfo()" ngNativeValidate>
            <div class="form-group">
              <label for="name">Nombre</label>
              <input id="name" type="text" class="form-control" name="name" [(ngModel)]="selectedEvent.name" required />
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="date">Fecha del evento</label>
                  <input id="date" type="date" class="form-control" [(ngModel)]="selectedEvent.date" name="date" required />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="time">Hora del evento</label>
                  <input id="time" type="time" class="form-control" [(ngModel)]="selectedEvent.time" name="time" required />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="place">Lugar</label>
              <input id="place" type="text" class="form-control" name="place" [(ngModel)]="selectedEvent.place" required />
            </div>
            <div class="form-group">
              <label for="totalTables">Número de mesas</label>
              <input id="totalTables" type="number" class="form-control" [(ngModel)]="selectedEvent.totalTables" name="totalTables"
                required>
            </div>
            <button type="submit" class="btn btn-yellow btn-block">Actualizar</button>
          </form>
        </ng-template>
      </li>
      <li ngbNavItem="turnTab">
        <a ngbNavLink>Turno de selección</a>
        <ng-template ngbNavContent>
          <form (ngSubmit)="updateEventFinalTurn()">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="finalSelectionDate">Fecha final de turnos</label>
                  <input id="finalSelectionDate" type="date" class="form-control" [(ngModel)]="selectedEvent.finalSelectionDate"
                    name="finalSelectionDate" required />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="finalSelectionTime">Hora final de turnos</label>
                  <input id="finalSelectionTime" type="time" class="form-control" [(ngModel)]="selectedEvent.finalSelectionTime"
                    name="finalSelectionTime" required />
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-yellow btn-block">Actualizar</button>
          </form>
        </ng-template>
      </li>
    </ul>
    <br>
    <div [ngbNavOutlet]="navEditEvent"></div>
  </div>
</ng-template>
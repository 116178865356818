<div class="card">
  <div class="card-body selection-card-body">
    <div class="d-flex justify-content-between">
      <div>
        <h4 class="d-inline-block">{{idUserType == 3 ? turn?.event?.name : event?.event?.name}}</h4>
        <div *ngIf="idUserType == 3 && turn && turn?.allEvents.length > 1" ngbDropdown class="d-inline-block ml-3 align-more-events-dropdown">
          <button class="btn btn-outline-success more-events-dropdown" id="dropdownEvents" ngbDropdownToggle>Más eventos</button>
          <div ngbDropdownMenu aria-labelledby="dropdownEvents">
            <button ngbDropdownItem *ngFor="let eventItem of turn?.allEvents" (click)="changeCurrentEvent(eventItem)">{{eventItem.name}}</button>
          </div>
        </div>
        <p *ngIf="idUserType == 3 && eventsNotFound == false" class="mb-1">{{turn?.event?.date | date: 'longDate' }} @ {{turn?.event?.time}} ({{turn?.event?.numberOfTables}} {{turn?.event?.numberOfTables > 1 ? 'mesas' : 'mesa'}})</p>
        <p *ngIf="idUserType != 3 && eventsNotFound == false" class="mb-1">{{event?.event?.date | date: 'longDate' }} @ {{event?.event?.time}} <span *ngIf="selectedUser != null">({{selectedUser?.numberOfTables}} {{selectedUser?.numberOfTables > 1 ? 'mesas compradas' : 'mesa comprada'}})</span></p>
      </div>
      <div *ngIf="isTurn == true && currentUser != null">
        <button *ngIf="alreadySelectedTables == true && showEditSelection == false" type="button" class="btn btn-secondary mt-2 mr-2" (click)="returnNoChanges()">Salir sin cambios</button>
        <button *ngIf="showEditSelection == false" type="button" class="btn btn-yellow mt-2" (click)="sendTableSelection()">Enviar selección</button>
        <button *ngIf="showEditSelection == true" type="button" class="btn btn-success mt-2" (click)="clickEditSelection()">Editar selección</button>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="eventsNotFound == false && isTurn == false && isTurnPassed == false">
  <div class="row d-flex justify-content-center">
    <div class="col-10 text-center">
      <p class="margin-turn-section">
        <span class="turn-header">Tu turno para seleccionar mesa es el</span><br>
        <span class="turn-subheader">{{prettyTurnDate}}, a las {{prettyTurnTime}}</span>
      </p>
      <p class="margin-turn-section pb-3">
        <span class="turn-current">Tiempo actual</span><br>
        <span class="turn-current">{{now}}</span>
      </p>
    </div>
  </div>
</div>

<div class="container" *ngIf="isTurnPassed == true">
  <div class="row d-flex justify-content-center">
    <div class="col-10 text-center">
      <p class="margin-turn-section">
        <span class="turn-header">Tu turno para seleccionar mesa ha terminado</span><br>
        <span class="turn-subheader">Favor de contactar al organizador en caso de dudas</span>
      </p>
    </div>
  </div>
</div>

<div class="container" *ngIf="eventsNotFound == true">
  <div class="row d-flex justify-content-center">
    <div class="col-10 text-center">
      <p class="margin-turn-section">
        <span class="turn-header">No se ha encontrado información de algún evento</span><br>
        <span class="turn-subheader">Favor de contactar al organizador en caso de dudas</span>
      </p>
    </div>
  </div>
</div>

<div *ngIf="isTurn == true">
  <div class="row m-0">
    <div class="col-lg-6 p-0 text-center">
      <img src="{{event?.event?.layout}}" class="fit-image">
    </div>
    <div class="col-lg-6 p-0">
      <div class="card no-radius card-selection">
        <div class="card-body">
          <div *ngIf="idUserType != 3">
            <h5>Selecciona un usuario</h5>
            <select [ngModel]="selectedUser" (ngModelChange)="onSelectUserDropdown($event)" class="custom-select" id="inputUsersEvent">
              <option></option>
              <option [ngValue]="user" *ngFor="let user of eventUsers">{{user.user.name}}</option>
            </select>
          </div>
          <br *ngIf="idUserType != 3">
          <h5 *ngIf="idUserType != 3 ">{{showEditSelection ? 'Selecciona las mesas o agrega comentarios' : 'Selecciona las mesas'}}</h5>
          <h5 *ngIf="idUserType == 3">{{showEditSelection ? 'Selecciona tus mesas o agrega comentarios' : 'Selecciona tus mesas'}}</h5>
          <p class="m-0"># Mesas compradas: {{idUserType == 3 ? turn?.event?.numberOfTables : selectedUser?.numberOfTables}}</p>
          <p class="m-0"># Mesas disponibles: {{availableTables}}</p>
          <p *ngIf="idUserType != 3">Mesas seleccionadas: <b>{{prettySelectedTables}}</b></p>
          <p *ngIf="idUserType == 3">Mesas seleccionadas: <b>{{prettySelectedTables}}</b></p>
          <div class="tables-list-section"
          ngbPopover="Da clic en el ícono de comentario de tu mesa seleccionada" popoverTitle="¿Tienes algún pedido especial sobre tu mesa?"
          triggers="manual" #p="ngbPopover" [attr.disabled]="isPopoverVisible(p)" placement="top-right">
            <div class="list-group">
              <div *ngFor="let table of event?.tables" class="list-group-item list-group-item-action d-flex align-items-center" [ngClass]="{'pointer': table.idUser == null}" (click)="clickTableRow(table)">
                <div class="table-number" [ngClass]="{'table-already-selected': table.idUser != null && table.idUser != currentUser, 'table-active': table.active}">
                  {{table.number}}
                </div>
                <span class="ml-3">{{table.name}}</span>
                <span *ngIf="table.active && showEditSelection == true" class="align-comments-icon pointer" (click)="openModalWithInfo(tableCommentsModal, 'tableCommentsModal', table, $event)">
                  <svg class="bi bi-chat" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M2.678 11.894a1 1 0 01.287.801 10.97 10.97 0 01-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 01.71-.074A8.06 8.06 0 008 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 01-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 00.244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 01-2.347-.306c-.52.263-1.639.742-3.468 1.105z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
</div>


<div *ngIf="isTurn == true && idUserType == 3">
  <div class="time-box text-center">
    <p class="time-box-text m-0">Tiempo restante<br>de tu turno</p>
    <span class="time-box-number"><b>{{timeCountDown}}</b></span>
  </div>
</div>

<ng-template #tableCommentsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Comentarios para la mesa #{{openedTable.number}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <form (ngSubmit)="updateTableComments()" ngNativeValidate>
        <label for="comments">¿Alguno(s) de tus invitados requiere(n) platillo especial (vegetariano, infantil, alérgico a algún alimento)? Por favor especifica el nombre del invitado, tipo de platillo especial y número de mesa en caso de tener más de una.</label>
        <textarea id="comments" type="text" class="form-control" name="comments" [(ngModel)]="openedTable.comments" required></textarea>
        <br>
        <button type="submit" class="btn btn-yellow btn-block">Enviar comentarios</button>
      </form>
    </div>
  </div>
</ng-template>
<app-footer></app-footer>
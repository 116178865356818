import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import 'moment/min/locales';
moment.locale('es')

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  public exportExcel(jsonData: any[], fileName: string, eventDate: string, eventUsers: any[]): void {
    eventDate = moment(eventDate).format("DDMMMYY")
    eventDate = eventDate.replace('.', '').toUpperCase();
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Detalle (Mesas)');
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.columns = [
      { header: '', key: 'name', width: 40 },
      { header: '', key: 'number', width: 10 },
      { header: '', key: 'tickets', width: 10 },
      { header: '', key: 'comments', width: 60 }
    ];
    worksheet.addRows(jsonData);
    var titleRow = worksheet.getRow(1);
    titleRow.values = [fileName]
    titleRow.font = { bold: true };
    var row = worksheet.getRow(3);
    row.values = ["NOMBRE DE GRADUADO", "# MESA", "BOLETOS", "COMENTARIOS (PLATILLOS ESPECIALES)"];
    worksheet.autoFilter = 'A3:D3';
    worksheet.getCell('A3').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00B050' },
      bgColor: { argb: '00B050' }
    };
    worksheet.getCell('B3').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00B050' },
      bgColor: { argb: '00B050' }
    };
    worksheet.getCell('C3').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00B050' },
      bgColor: { argb: '00B050' }
    };
    worksheet.getCell('D3').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00B050' },
      bgColor: { argb: '00B050' }
    };
    var initialRow = 4;
    var cellsWithBorder = ['A3', 'B3', 'C3', 'D3'];
    for (var i = 0; i < jsonData.length; i++) {
      cellsWithBorder.push('A' + (initialRow + i));
      cellsWithBorder.push('B' + (initialRow + i));
      cellsWithBorder.push('C' + (initialRow + i));
      cellsWithBorder.push('D' + (initialRow + i));
    }
    cellsWithBorder.map(key => {
      worksheet.getCell(key).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      worksheet.getCell(key).alignment = { wrapText: true };
    });

    let worksheet2 = workbook.addWorksheet('Detalle (Usuarios)');
    worksheet2.addRow([]);
    worksheet2.addRow([]);
    worksheet2.addRow([]);
    worksheet2.columns = [
      { header: '', key: 'name', width: 40 },
      { header: '', key: 'email', width: 40 },
      { header: '', key: 'numberOfTables', width: 10 },
      { header: '', key: 'turn', width: 20 }
    ];
    worksheet2.addRows(eventUsers);
    var titleRow = worksheet2.getRow(1);
    titleRow.values = [fileName]
    titleRow.font = { bold: true };
    var row = worksheet2.getRow(3);
    row.values = ["NOMBRE DE USUARIO", "CORREO", "# MESAS", "TURNO"];
    worksheet2.autoFilter = 'A3:D3';
    worksheet2.getCell('A3').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00B050' },
      bgColor: { argb: '00B050' }
    };
    worksheet2.getCell('B3').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00B050' },
      bgColor: { argb: '00B050' }
    };
    worksheet2.getCell('C3').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00B050' },
      bgColor: { argb: '00B050' }
    };
    worksheet2.getCell('D3').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00B050' },
      bgColor: { argb: '00B050' }
    };
    var initialRow = 4;
    var cellsWithBorder = ['A3', 'B3', 'C3', 'D3'];
    for (var i = 0; i < eventUsers.length; i++) {
      cellsWithBorder.push('A' + (initialRow + i));
      cellsWithBorder.push('B' + (initialRow + i));
      cellsWithBorder.push('C' + (initialRow + i));
      cellsWithBorder.push('D' + (initialRow + i));
    }
    cellsWithBorder.map(key => {
      worksheet2.getCell(key).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      worksheet2.getCell(key).alignment = { wrapText: true };
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Asignación de mesas ' + fileName + ' ' + eventDate + '.xlsx');
    });
  }
}
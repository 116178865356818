<header id="main-navbar" class="navbar navbar-expand flex-md-row bd-navbar py-0">
  <a href="https://www.wemakeitmx.com"><img class="pointer" height="80" src="../../assets/img/wmi-logo.png"></a>
</header>

<div id="login-background">
  <div class="">
    <div class="container landing-padding-top">
      <div class="row justify-content-center">
        <div class="col-lg-6 text-center">
          <h2>Bienvenido a We Make It</h2>
          <br>
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="card">
                <div class="card-body">
                  <p>Inicia sesión</p>
                  <form (ngSubmit)="clickSignIn()" ngNativeValidate>
                    <input type="email" class="form-control" name="email" [(ngModel)]="user.email" placeholder="Correo electrónico" required/>
                    <br>
                    <input type="password" class="form-control" name="password" [(ngModel)]="user.password"
                      placeholder="Contraseña" required/>
                    <br>
                    <button type="submit" class="btn btn-yellow btn-block">Iniciar sesión</button>
                    <br>
                    <a *ngIf="showLinkConfirm"><small class="pointer" (click)="goToResendConfirmationEmail()">Reenviar correo de confirmación</small></a>
                    <br *ngIf="showLinkConfirm">
                    <a><small class="pointer" (click)="goToRecoverPassword()">¿Olvidaste tu contraseña?</small></a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuardService as AuthGuard } from './_services/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EventComponent } from './event/event.component';
import { StaffComponent } from './staff/staff.component';
import { SelectionComponent } from './selection/selection.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ResendConfirmationComponent } from './resend-confirmation/resend-confirmation.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'c/:c', component: LoginComponent },
  { path: 'unsubscribe/:c/:e', component: UnsubscribeComponent },
  { path: 'confirmation', component: ResendConfirmationComponent },
  { path: 'password-change', component: ChangePasswordComponent },
  { path: 'password-reset', component: ResetPasswordComponent },
  { path: 'app',
    component: NavbarComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard] 
      },
      {
        path: 'event/:id',
        component: EventComponent,
        canActivate: [AuthGuard] 
      },
      {
        path: 'staff',
        component: StaffComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'selection/:id',
        component: SelectionComponent,
        canActivate: [AuthGuard] 
      },
      {
        path: 'selection',
        component: SelectionComponent,
        canActivate: [AuthGuard]
      },
      { path: '**', redirectTo: '/app/home' }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';
import * as moment from 'moment';
import 'moment/min/locales';
moment.locale('es')
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css']
})
export class SelectionComponent implements OnInit {

  turn: any;
  now: any;
  turnFormatted: any;
  finalTurnFormatted: any;
  isTurn: boolean = false;
  isTurnPassed: boolean = false;
  prettyTurnDate: any;
  prettyTurnTime: any;
  idEvent: number;
  event: any;
  turnSelectedTables = [];
  prettySelectedTables: string = "";
  currentUser: number = null;
  timeCountDown: any;
  idUserType: number;
  eventUsers = [];
  selectedUser: any;
  alreadySelectedTables: boolean = false;
  showEditSelection: boolean = false;
  availableTables: number = 0;
  openedTable: any;
  eventsNotFound: boolean = false;
  countdownInterval;
  showCommentsPopover: boolean = false;

  constructor(public api: ApiService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router, private modalService: NgbModal) { 
    this._timerTick();
  }

  ngOnInit(): void {
    this.idUserType = +localStorage.getItem('idUserType');
    this.spinner.show();
    if (this.idUserType == 3) {
      this.currentUser = +localStorage.getItem('idUser');
      this.getTurn(0);
    } else {
      this.idEvent = +this.route.snapshot.paramMap.get("id");
      this.isTurn = true;
      this.getEventInfo(this.idEvent);
      this.getEventUsers(this.idEvent);
    }
  }

  isPopoverVisible(popover: NgbPopover) {
    if (this.showCommentsPopover) {
      popover.open();
    }
    return this.showCommentsPopover;
  }

  private _timerTick() {
    this.now = moment().format("DD [de] MMMM [del] YYYY, hh:mm:ss A");

    setTimeout(() => this._timerTick(), 1000);
  }

  private _countDown(diffTime) {
    this.countdownInterval = setInterval(() => {
      diffTime = moment.duration(diffTime - 1000, 'milliseconds');
      if (diffTime._milliseconds <= 0) {
        this.isTurn = false;
        this.isTurnPassed = true;
      }
      var diffDays = diffTime.days() < 10 ? "0" + diffTime.days() : diffTime.days();
      var diffHours = diffTime.hours() < 10 ? "0" + diffTime.hours() : diffTime.hours();
      var diffMinutes = diffTime.minutes() < 10 ? "0" + diffTime.minutes() : diffTime.minutes();
      var diffSeconds = diffTime.seconds() < 10 ? "0" + diffTime.seconds() : diffTime.seconds();
      if (diffTime.days() > 0) {
        this.timeCountDown = diffDays + ":" + diffHours + ":" + diffMinutes + ":" + diffSeconds;
      } else {
        this.timeCountDown = diffHours + ":" + diffMinutes + ":" + diffSeconds;
      }
    }, 1000);
  }

  getTurn(selectedIdEvent) {
    this.turn = null;
    this.eventsNotFound = false;
    this.timeCountDown = null;
    clearInterval(this.countdownInterval);
    this.api.getUserTurn(selectedIdEvent).subscribe((data) => {
      if (data.meta.code == 200) {
        if (data.data && data.data.length > 0) {
          this.turn = data.data[0];
          this.prettyTurnDate = moment(this.turn.date, "YYYY-MM-DD").format("DD [de] MMMM [del] YYYY");
          this.prettyTurnTime = moment(this.turn.time, "HH:mm:ss").format("hh:mm:ss A");
          this.turnFormatted = moment(this.turn.date + " " + this.turn.time);
          this.finalTurnFormatted = moment(this.turn.finalSelectionDate + " " + this.turn.finalSelectionTime);
          if (moment().isAfter(this.finalTurnFormatted)) {
            this.isTurnPassed = true;
            this.spinner.hide();
          } else {
            if (moment().isBetween(this.turnFormatted, this.finalTurnFormatted)) {
              this.isTurn = true;
              let diffTime = moment(this.finalTurnFormatted).diff(moment());
              this._countDown(diffTime);
              this.getEventInfo(this.turn.event.idEvent);
            } else {
              this.isTurn = false;
              this.spinner.hide();
            }
          }
        } else {
          this.isTurn = false;
          this.eventsNotFound = true;
          this.spinner.hide();
        }
      } else {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        this.spinner.hide();
      }
    }, (err) => {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
      localStorage.clear();
      this.router.navigate(['/']);
    });
  }

  getEventInfo(id: number) {
    this.event = null;
    this.availableTables = 0;
    this.turnSelectedTables = [];
    this.prettySelectedTables = "";
    this.showEditSelection = false;
    this.alreadySelectedTables = false;
    this.api.getEventById(id).subscribe((data) => {
      if (data.meta.code == 200) {
        this.event = data.data;
        this.event.tables = this.event.tables.filter(table => table.status == 1);
        if (this.currentUser != null) {
          for (var i = 0; i < this.event.tables.length; i++) {
            if (this.event.tables[i].idUser == this.currentUser) {
              this.event.tables[i].active = true;
              this.turnSelectedTables.push(this.event.tables[i]);
            }
            if (this.event.tables[i].idUser == null) {
              this.availableTables += 1;
            }
          }
          this.prettySelectedTables = this.turnSelectedTables.map(function (elem) {
            return elem.number;
          }).join(", ");
          if (this.turnSelectedTables.length > 0) {
            this.alreadySelectedTables = true;
            this.showEditSelection = true;
            this.showCommentsPopover = true;
            setTimeout(() => {
              this.showCommentsPopover = false;
            }, 1000)
          }
        } else {
          for (var i = 0; i < this.event.tables.length; i++) {
            if (this.event.tables[i].idUser == null) {
              this.availableTables += 1;
            }
          }
        }
      } else {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      }
      this.spinner.hide();
    }, (err) => {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
      localStorage.clear();
      this.router.navigate(['/']);
    });
  }
  
  clickTableRow(table) {
    if (this.showEditSelection == false) {
      if (this.currentUser != null) {
        if (table.idUser != null && table.idUser != this.currentUser) {
          return;
        }
        if (table.idUser == null || table.idUser == this.currentUser) {
          if (localStorage.getItem("unsavedChanges") == null) {
            localStorage.setItem("unsavedChanges", "true");
          }
          this.prettySelectedTables = "";
          if (table.active) {
            table.active = false;
            const found = this.turnSelectedTables.findIndex(element => element.number == table.number);
            this.turnSelectedTables.splice(found, 1);
          } else {
            if (this.idUserType == 3) {
              if (this.turnSelectedTables.length < this.turn.event.numberOfTables) {
                table.active = true;
                this.turnSelectedTables.push(table);
              } else {
                Swal.fire({ icon: 'warning', title: 'Atención', text: 'Tu límite de mesas a seleccionar es ' + this.turn.event.numberOfTables });
              }
            } else {
              if (this.turnSelectedTables.length < this.selectedUser.numberOfTables) {
                table.active = true;
                this.turnSelectedTables.push(table);
              } else {
                Swal.fire({ icon: 'warning', title: 'Atención', text: 'El límite de mesas del usuario es ' + this.selectedUser.numberOfTables });
              }
            }
          }
          this.prettySelectedTables = this.turnSelectedTables.map(function (elem) {
            return elem.number;
          }).join(", ");
        }
      } else {
        if (this.idUserType == 3) {
          Swal.fire({ icon: 'error', title: 'Error', text: 'No hemos encontrado tú información. Favor de volver a cargar la página' });
        } else {
          Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de primero seleccionar un usuario' });
        }
      }
    } else {
      Swal.fire({ icon: 'warning', title: 'Atención', text: 'Da clic en el botón de Editar selección para comenzar a realizar cambios' });
    }
  }



  sendTableSelection(){
    if (this.currentUser != null) {
      if (this.turnSelectedTables.length > 0 && ((this.idUserType == 3 && this.turnSelectedTables.length == this.turn.event.numberOfTables) || (this.idUserType != 3 && this.turnSelectedTables.length == this.selectedUser.numberOfTables))) {
        this.spinner.show();
        let arrayOfTables = [];
        for (var i = 0; i < this.turnSelectedTables.length; i++) {
          arrayOfTables.push(this.turnSelectedTables[i].idTable);
        }
        let body = {
          data: {
            idEvent: this.idUserType == 3 ? this.turn.event.idEvent : this.idEvent,
            idUser: this.currentUser,
            tables: arrayOfTables,
          }
        }
        if (body.data.idEvent && body.data.idUser) {
          this.api.submitTableSelection(body).subscribe((result) => {
            if (result.meta.code == 200) {
              var failedTablesArray = result.data;
              if (failedTablesArray != null && failedTablesArray.length == 0) {
                localStorage.removeItem("unsavedChanges");
                if (this.idUserType == 3) {
                  Swal.fire('', 'Tu selección de mesas ha sido guardada exitosamente', 'success');
                  this.getEventInfo(this.turn.event.idEvent);
                } else {
                  Swal.fire('', 'La selección de mesas ha sido guardada exitosamente', 'success');
                  this.currentUser = null;
                  this.selectedUser = null;
                  this.getEventInfo(this.idEvent);
                }
              } else {
                var failedTablesText = "";
                for (var i = 0; i < failedTablesArray.length; i++) {
                  const found = this.event.tables.find(element => element.idTable == failedTablesArray[i].idTable);
                  failedTablesText += "#" + found.number;
                  if (i < failedTablesArray.length - 1) {
                    if (i == failedTablesArray.length - 2) {
                      failedTablesText += " y "
                    } else {
                      failedTablesText += ", "
                    }
                  }
                }
                if (failedTablesArray.length == 1) {
                  Swal.fire({ icon: 'warning', title: 'Lo sentimos', text: 'La mesa ' + failedTablesText + ' ya fue seleccionada por alguien más. Favor de seleccionar otra mesa' });
                } else {
                  Swal.fire({ icon: 'warning', title: 'Lo sentimos', text: 'Las mesas ' + failedTablesText + ' ya fueron seleccionadas por alguien más. Favor de seleccionar otras mesas' });
                }
                if (this.idUserType == 3) {
                  this.getEventInfo(this.turn.event.idEvent);
                } else {
                  this.getEventInfo(this.idEvent);
                }
              }
            } else {
              if (result.meta.status == "Turn is not valid") {
                localStorage.removeItem("unsavedChanges");
                Swal.fire({
                  icon: 'error', title: 'Turno de selección inválido', text: 'La página se volvera a cargar para validar tu turno',
                  timer: 3000,
                  onClose: () => {
                    window.location.reload();
                  }
                }).then((result) => {
                  window.location.reload();
                });
              } else if (result.meta.status == "Number of tables selected is more than the allowed") {
                Swal.fire({ icon: 'warning', title: 'Atención', text: 'El número de mesas que seleccionaste es mayor al que tienes permitido' });
              } else {
                Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
              }
              if (this.idUserType == 3) {
                this.getEventInfo(this.turn.event.idEvent);
              } else {
                this.getEventInfo(this.idEvent);
              }
            }
            this.spinner.hide();
          }, (err) => {
            Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
            if (this.idUserType == 3) {
              this.getEventInfo(this.turn.event.idEvent);
            } else {
              this.getEventInfo(this.idEvent);
            }
            this.spinner.hide();
          });
        } else {
          localStorage.removeItem("unsavedChanges");
          Swal.fire({
            icon: 'error', title: 'Lo sentimos', text: 'Ocurrió un problema al obtener tu información. La página se volverá a cargar para intentarlo de nuevo',
            timer: 3000,
            onClose: () => {
              window.location.reload();
            }
          }).then((result) => {
            window.location.reload();
          });
        }
      } else {
        if (this.idUserType == 3) {
          Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de seleccionar la cantidad completa de mesas que compraste' });
        } else {
          Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de seleccionar la cantidad completa de mesas que compró el usuario' });
        }
      } 
    } else {
      if (this.idUserType == 3) {
        localStorage.removeItem("unsavedChanges");
        Swal.fire({
          icon: 'error', title: 'Lo sentimos', text: 'Ocurrió un problema al obtener tu información. La página se volvera a cargar para intentarlo de nuevo',
          timer: 3000,
          onClose: () => {
            window.location.reload();
          }
        }).then((result) => {
          window.location.reload();
        });
      } else {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de primero seleccionar un usuario' });
      }
    }
  }

  getEventUsers(id: number) {
    this.eventUsers = [];
    this.api.getEventUsers(id).subscribe((data) => {
      if (data.meta.code == 200) {
        this.eventUsers = data.data;
        this.eventUsers = this.eventUsers.filter(eventUser => eventUser.status == 1);
      } else {
        Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
        this.spinner.hide();
      }
    }, (err) => {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
      localStorage.clear();
      this.router.navigate(['/']);
    });
  }

  onSelectUserDropdown(user) {
    this.currentUser = user.idUser;
    this.selectedUser = user;
    this.prettySelectedTables = "";
    this.turnSelectedTables = [];
    this.alreadySelectedTables = false;
    this.showEditSelection = false;
    this.event.tables.map(table => { return table.active = false; })
    for (var i = 0; i < this.event.tables.length; i++) {
      if (this.event.tables[i].idUser == this.currentUser) {
        this.event.tables[i].active = true;
        this.turnSelectedTables.push(this.event.tables[i]);
      }
    }
    this.prettySelectedTables = this.turnSelectedTables.map(function (elem) {
      return elem.number;
    }).join(", ");
    if (this.turnSelectedTables.length > 0) {
      this.alreadySelectedTables = true;
      this.showEditSelection = true;
    }
  }

  clickEditSelection() {
    this.showEditSelection = false;
    localStorage.setItem("unsavedChanges", "true");
  }

  returnNoChanges() {
    Swal.fire({
      title: '',
      text: '¿Estás seguro de querer salir sin cambios?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem("unsavedChanges");
        this.showEditSelection = true;
        if (this.idUserType == 3) {
          this.getEventInfo(this.turn.event.idEvent);
        } else {
          this.getEventInfo(this.idEvent);
        }
      }
    })
  }

  openModalWithInfo(content, typeOfModal, selectedData, event) {
    event.stopPropagation();
    if (typeOfModal == 'tableCommentsModal') {
      this.openedTable = JSON.parse(JSON.stringify(selectedData));
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  updateTableComments() {
    this.spinner.show();
    let body = {
      data: {
        idEvent: this.idUserType == 3 ? this.turn.event.idEvent : this.idEvent,
        idUser: this.currentUser,
        idTable: this.openedTable.idTable,
        comments: this.openedTable.comments
      }
    }
    if (body.data.idEvent && body.data.idUser && body.data.idTable) {
      if (body.data.comments && body.data.comments.length > 0) {
        this.api.updateTableComments(body).subscribe((result) => {
          if (result.meta.code == 200) {
            Swal.fire('', 'Los comentarios han sido enviados exitosamente', 'success');
            const findIndex = this.event.tables.findIndex(element => element.idTable == body.data.idTable);
            this.event.tables[findIndex].comments = body.data.comments;
            this.modalService.dismissAll();
          } else {
            Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          }
          this.spinner.hide();
        }, (err) => {
          Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
          this.spinner.hide();
        });
      } else {
        Swal.fire({ icon: 'warning', title: 'Atención', text: 'Favor de escribir sus comentarios' });
        this.spinner.hide();
      }
    } else {
      Swal.fire({ icon: 'error', title: 'Ha ocurrido un error', text: 'Favor de volver a intentarlo' });
      this.spinner.hide();
    }
  }

  changeCurrentEvent(eventItem) {
    if (this.turnSelectedTables.length > 0 && !this.showEditSelection) {
      Swal.fire({
        title: '',
        text: 'Puede que haya cambios sin guardar, ¿estás seguro de querer continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.getTurn(eventItem.idEvent);
        }
      })
    } else {
      this.spinner.show();
      this.getTurn(eventItem.idEvent);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../_services/api.service';
import Swal from 'sweetalert2'
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  email: string = "";
  showUnsubscribed: boolean = false;
  showError: boolean = false;

  constructor(public api: ApiService, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.email = atob(this.route.snapshot.paramMap.get("e"));
  }


  unsubscribe() {
    var c = this.route.snapshot.paramMap.get("c");
    var body = {
      data: {
        c: c
      }
    }
    this.api.unsubscribe(body).subscribe((result) => {
      if (result.meta.code == 200) {
        this.showUnsubscribed = true;
        this.spinner.hide()
      } else {
        this.showError = true;
        this.spinner.hide()
      }
    }, (err) => {
        this.showError = true;
      this.spinner.hide();
    });
  }

  goToLogin() {
    this.router.navigate(['/']);
  }
}

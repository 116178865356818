import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ExportService } from '../_services/export.service';
import * as moment from 'moment';

@Directive({
  selector: '[appExport]'
})
export class ExportDirective {

  constructor(private exportService: ExportService) { }

  @Input('appExport') dataToExport: any[];
  @Input() fileName: string;
  @Input() eventDate: string;
  @Input('eventUsers') eventUsers: any[];

  @HostListener('click', ['$event']) onClick($event) {
    var formattedData = [];
    for (var i = 0; i < this.dataToExport.length; i++) {
      var temp = {
        name: this.dataToExport[i].name || "S/A",
        number: this.dataToExport[i].number, 
        tickets: '',
        comments: this.dataToExport[i].comments || ''
      }
      formattedData.push(temp)
    }
    var formattedUsers = [];
    for (var i = 0; i < this.eventUsers.length; i++) {
      var temp2 = {
        name: this.eventUsers[i].user.name,
        email: this.eventUsers[i].user.email,
        numberOfTables: this.eventUsers[i].numberOfTables,
        turn: moment(this.eventUsers[i].turn.date, "YYYY-MM-DD").format("DD-MM-YYYY") + " " + this.eventUsers[i].turn.time
      }
      formattedUsers.push(temp2);
    }
    this.exportService.exportExcel(formattedData, this.fileName, this.eventDate, formattedUsers);
  }

}